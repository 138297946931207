import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { openModal } from 'app/services/events/EventService';
import { getStorageItem, setStorageItem } from 'app/sharedServices/storage/storage';
import { parseIdToken } from 'app/sharedServices/authentication/authentication';
import { ResponseBody } from '../api/dataExtraction';

const ACCEPTABLE_RETRY_STATUSES = [429];
const RETRY_LIMIT = 3;

export const apigeeUnderMaintenanceMsg = 'maintenance_mode';
const preRequestHandler = async (config: InternalAxiosRequestConfig) => {
  const storageToken = getStorageItem('idToken', 'local');
  const idToken = parseIdToken(storageToken);
  config.headers['AuthProvider'] = idToken?.issuer;
  if (!config.headers.Authorization && !!idToken?.jwt) {
    config.headers['Authorization'] = `Bearer ${idToken.jwt}`;
  }
  if (!config.headers['X-API-Key']) {
    config.headers['X-API-Key'] = process.env.X_API_KEY;
  }

  const data = config.data;
  if (data) {
    config.data = encodeBody(data);
  }

  return config;
};

const errorRequestHandler = (error: unknown) => {
  return Promise.reject(error);
};

const successResponseHandler = (response: ResponseBody) => {
  const retriesObject = getStorageItem('URLRetries') ?? {};
  setStorageItem('URLRetries', { ...retriesObject, [response.config.url]: 0 });
  const data = response.data;
  if (data) {
    response.data = decodeResponse(data);
  }
  return response.data;
};

const errorResponseHandler = async (error: AxiosError) => {
  if (
    error?.response?.status === 503 &&
    (error?.response?.data as Record<string, any>).error === apigeeUnderMaintenanceMsg
  ) {
    setStorageItem('lastPathForMaintenance', window.location.pathname, 'session');
    window.location.href = '/under-maintenance';
    return;
  }
  if (isExpiredLogoutError(error)) {
    return;
  }
  if (ACCEPTABLE_RETRY_STATUSES.includes(error.response.status)) {
    const retriesObject = getStorageItem('URLRetries') ?? {};
    const currentRetries = retriesObject[error.config.url] ?? 0;
    if (!(currentRetries >= RETRY_LIMIT - 1)) {
      setStorageItem('URLRetries', { ...retriesObject, [error.config.url]: currentRetries + 1 });
      return await HttpClient(error.config);
    }
  }
  openModal('ERROR', {
    message: 'We are experiencing some technical problems, please bear with us while we try to fix this issue.',
    buttons: {
      close: {
        handler: () => {
          location.reload();
        },
      },
    },
  });

  throw Error('An error happened' + error);
};

const HttpClient = axios.create({
  baseURL: process.env.OBS_API_URL,
});

HttpClient?.interceptors?.request?.use(preRequestHandler, errorRequestHandler);
HttpClient?.interceptors?.response?.use(successResponseHandler, errorResponseHandler);

export const encodeBody = (postBody: Record<string, any>) => {
  let encodedData = JSON.stringify(postBody);
  encodedData = encodedData.replace(new RegExp("'", 'g'), '&#x27');
  return JSON.parse(encodedData);
};

export const decodeResponse = (responseData: Record<string, string>) => {
  let decodedResponse = JSON.stringify(responseData);
  decodedResponse = decodedResponse.replace(new RegExp('&#x27', 'g'), "'");
  return JSON.parse(decodedResponse);
};

const isExpiredLogoutError = (error: AxiosError) => {
  const requestUrl = (error?.request?.responseURL || error?.request?.url) as string;
  if (requestUrl?.match(/logout/) && error?.response?.status === 401) {
    return true;
  }
  return false;
};

export default HttpClient;
